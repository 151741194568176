
















































































import rService from '@/services/RequestService/RequestServiceSetup';
import alertS from '@/services/AlertService/AlertService';

import { copyTextToClipboard } from '@/services/Copy';
import { mapGetters, mapActions } from 'vuex';

export default {
  data: () => ({
    loadingT: true,
    dialog: false,
    loadingR: false,
    items: [],
    search: null,
    isLoadingField: false,
    modelDiscord: '',
    linkNPM: 'https://www.npmjs.com/package/checkleakedcc',
    linkRapidApi: 'https://rapidapi.com/airaudoeduardo/api/checkleakedcc-official/',
    linkZapier: 'https://zapier.com/developer/public-invite/189425/0676b3acf72597bb122e6fc6d3f10bba/',
    headers: [
      {
        text: 'Bot Source',
        align: 'start',
        sortable: false,
        value: 'bot_type',
      },
      { text: 'User ID', value: 'user_id' },
      { text: 'User Info', value: 'member' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    values: [],
    editedIndex: -1,
    defaultItem: {
      bot_type: '',
      user_id: '',
    },
  }),

  components: {
    DialogLinks: () => import('@/components/BotLinks/Dialog.vue'),
  },

  computed: {
    ...mapGetters(['userInfo', 'loggedIn', 'loading']),
    ...mapGetters('socket', ['initializeBotLinks']),
    textLog() {
      if (this.loading) {
        return this.t('loading');
      } else {
        return this.t('accessFirst');
      }
    },
    linkApiKey() {
      if (this.userInfo.bot_token) {
        return '/swagger/index.html?api_key=' + this.userInfo.bot_token;
      } else {
        return '';
      }
    },
  },

  watch: {
    initializeBotLinks(newValue) {
      this.initialize();
    },
    dialog(val) {
      val || this.close();
    },
    'userInfo.unique_id'() {
      this.initialize();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    t(text) {
      return this.$vuetify.lang.t('$vuetify.botLinks.' + text);
    },
    ...mapActions(['regenerateToken']),
    async regenerate() {
      this.loadingR = true;
      await this.regenerateToken();
      this.loadingR = false;
    },
    copy(text, e) {
      const res = copyTextToClipboard(text);

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    async initialize() {
      console.log('INITIALIZE');
      if (this.userInfo.unique_id && this.userInfo.platform) {
        this.loadingT = true;
        console.log('Request');
        const values = await rService.get(`bot_links/all/${this.userInfo.platform}`);
        this.values = values;
        this.loadingT = false;
      }
    },

    editItem(item) {
      this.editedIndex = this.values.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      this.cleanFields();
    },

    deleteItem(item) {
      const index = this.values.indexOf(item);
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: 'You are about to delete this Bot Link!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes!',
        })
        .then(async (result) => {
          if (result.value) {
            alertS.loading('Deleting...');
            const result = await rService.post(`bot_links/delete/${this.userInfo.platform}`, { id: item.id });
            this.initialize();
            this.$swal.close();
          }
        });
    },

    close() {
      this.dialog = false;
    },
  },
};
